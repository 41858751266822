// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-relief-resources-src-pages-404-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-business-and-freelance-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/business-and-freelance.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-business-and-freelance-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-find-help-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/find-help.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-find-help-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-food-and-finance-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/food-and-finance.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-food-and-finance-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-give-help-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/give-help.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-give-help-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-health-and-family-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/health-and-family.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-health-and-family-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-index-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-local-and-state-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/local-and-state.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-local-and-state-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-misc-resources-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/misc-resources.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-misc-resources-js" */),
  "component---node-modules-gatsby-theme-relief-resources-src-pages-submit-js": () => import("./../node_modules/gatsby-theme-relief-resources/src/pages/submit.js" /* webpackChunkName: "component---node-modules-gatsby-theme-relief-resources-src-pages-submit-js" */)
}

